import React from 'react'
import LoaderSVG from './../assets/spinner.svg'
const Loader = ({ message }) => {
    return (
        <div className='flex flex-col  items-center justify-center'>
            <img
                width={50}
                height={50}
                src={LoaderSVG}
                alt="spinner" />
            <p className='text-[16px] text-Grey-50 font-medium'>{message}</p>
        </div>
    )
}

export default Loader