import { useState } from 'react';
import World from './../assets/World.png'
import Navbar, { HamburgerMenu, checkActive, redirectTo } from '../component/Navbar';
import Footer from '../component/Footer';
import Card from '../component/Card';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { FeatureCardContent, FeatureCardContent2, TabsContent, Name } from '../utilities/constants';

const Landing = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 })
  const [isActive, setIsActive] = useState(0);
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const Tabs = ({ img, imgHeading, index, content, handleClick, contenHeading }) => {
    return (<>

      <Card className='flex max-lg:flex-col lg:w-[1050px]  Blue   w-full relative'>
        <div className='flex lg:absolute text-center left-[-24px] bg-white p-6 rounded-lg w-[228px]  max-lg:w-[170px] items-center justify-center gap-2 flex-col'>
          <img className='max-lg:w-[100px]' width={150} height={150} src={img} alt="" />
          <h2 className={'text-Dark-Blue-100 text-base max-lg:text-sm text-center font-bold '}>{imgHeading}</h2>
        </div>
        <div className='w-[280px] max-lg:hidden'>

        </div>
        <div>
          <h2 className='text-[24px] text-left max-lg:text-[16px] max-lg:leading-6 max-lg:pb-5 lg:mb-5 font-bold text-Dark-Blue-80 leading-[32px]'>
            {contenHeading}
          </h2>
          <ul className='list-disc items-start flex flex-col gap-[20px] max-lg:gap-3'>
            {content.length > 0 && content.map(item => <li className=' text-Grey-80 text-[20px] max-lg:text-[16px] leading-[140%]'>{item}</li>)}
          </ul>
        </div>
      </Card>

    </>
    )
  }


  const FeatureCard = ({ heading, text, img }) =>
    <Card className={'w-full flex g-3 flex-col justify-center  items-start'}>
      <img className=' mb-4' height={58} width={58} src={img} alt="" />
      <h2 className='text-Dark-Blue-80 leading-[125%] text-lg max-lg:text-base font-bold mb-2 '>{heading}</h2>
      <p className='text-Grey-80 text-[20px] max-lg:text-[18px] font-normal leading-[25px] '>{text}</p>

    </Card>

  const FeatureCard2 = ({ heading, text, subheading, isNew }) =>
    <div className='w-full flex g-3 flex-col justify-start items-start bg-white px-6 py-7 rounded-xl'>
      <span className={`text-[12px] ${isNew ? '' : 'invisible'}  rounded-lg bg-[#F39A3133]  font-bold py-1 px-3 mb-2`}>Coming Soon</span>
      <h2 className='text-Primary-Blue-100 text-base font-bold leading-[32px]'>{heading}</h2>
      <h3 className='text-sm font-medium  text-Dark-Blue-80 mb-3'>{subheading}</h3>
      <p className='text-Grey-80  font-normal'>{text}</p>
    </div>


  return (
    <>

      {/* #1 */}
      <section className='relative min-h-screen max-lg:min-h-screen  max-lg:justify-start  flex  justify-center items-center max-lg:flex-col'>
        <Navbar isLogin={localStorage.imIsLoggedIn} funcOpenHamburgerMenu={() => setOpenHamburgerMenu(!openHamburgerMenu)} />
        {isTabletOrMobile &&
          (openHamburgerMenu &&
            <HamburgerMenu />
          )
        }
        <div className='z-0 absolute top-0 left-0 w-full lg:min-h-[70vh] max-lg:h-[50vh] bg-landing-image max-lg:bg-contain max-lg:bg-[10%] blur-[60px]'>
        </div>
        <div className='lg:w-[1140px] max-xl:w-[1240px] h-full items-center  max-lg:w-full max-lg:p-5 z-2 blur-0 flex max-lg:flex-col-reverse max-lg:h-screen max-lg:justify-center'>
          <div className='flex  lg:w-full flex-col gap-4 max-lg:items-center max-lg:w-full'>
            <h1 className='Linear Heading max-lg:text-[32px] max-lg:leading-[130%] max-lg:text-center'>
              Connect Apps Using <br className='' /> Open API
            </h1>
            <p className='Text max-lg:text-center'>
              Integrate Apps through {Name} APIs and <br className='max-lg:hidden' />
              explore the limitless possibilities of investing
            </p>
            <button
              onClick={() => { window.location.href = '/oapi/v1/im/login' }}
              className='Button text-base mt-[10px] max-lg:w-full max-lg:rounded-md flex max-lg:items-center justify-center'>
              Get Started
            </button>
          </div>
          <div className='flex items-center justify-end lg:w-2/3'>
            <img className='max-lg:w-[179px] lg:w-[388px]' src={World} alt="World-SVG" />
          </div>
        </div>
      </section>
      {/* #2 */}
      <section className='min-h-screen w-full flex items-center justify-center'>
        <div className='w-[90vw] max-lg:w-full max-lg:p-5 flex flex-col justify-start gap-10 items-center'>
          <h1 className='Heading max-lg:text-lg max-lg:pt-10 max-lg:leading-8 max-lg:text-center'>
            What Makes HDFC SKY Limitless
          </h1>
          <div className='grid w-full max-lg:flex max-lg:flex-wrap grid-cols-3 gap-5'>
            {FeatureCardContent.length > 0 &&
              FeatureCardContent.map((item) => (
                <FeatureCard
                  heading={item.heading}
                  img={item.img}
                  text={item.text}
                />))
            }
          </div>
        </div>
      </section>
      {/* #3 */}
      <section className='min-h-[120vh] max-lg:min-h-fit mb-[100px] max-lg:mb-0 w-full flex items-start justify-center'>
        <div className='w-[860px] max-lg:w-full max-lg:p-5 flex flex-col gap-9'>
          <h1 className='Heading max-lg:text-lg max-lg:hidden max-lg:leading-[130%] lead lg:leading-[86.4px] text-center'>Unlock A World Of <br /> Possibilities With Our API</h1>
          <h1 className='text-center font-bold text-base lg:hidden'>Unlock A World Of <br /> Possibilities With Our API</h1>

          <div className='flex flex-col relative justify-center items-center w-full gap-5'>

            {TabsContent.length > 0 && TabsContent.map((item, index) =>
              <Tabs img={item.img} index={index} content={item.content} contenHeading={item.contentHeading} imgHeading={item.imgHeading} />
            )}
          </div>
        </div>
      </section>
      {/* #4 */}
      <section className='min-h-screen Background2 flex justify-center items-center max-lg:p-5'>
        <div className='Inner_Background2 flex justify-center items-center min-h-screen w-full'>
          <div className='w-[1140px] max-xl:w-[1240px] max-lg:w-full flex flex-col gap-9 max-lg:gap-5'>
            <h1 className='Heading Linear lg:leading-[86.4px] text-center max-lg:text-lg max-lg:leading-10'>Everything You Need Is Now Under One SKY</h1>
            <div className='grid grid-cols-3 gap-7 max-lg:flex max-lg:flex-col'>
              {FeatureCardContent2.length > 0 &&
                FeatureCardContent2.map((item) => (
                  <FeatureCard2
                    heading={item.heading}
                    subheading={item.subheading}
                    isNew={item.isNew}
                    text={item.text} />))
              }
            </div>
          </div>
        </div>
      </section>
      {/* #5 */}
      {/* <section className='min-h-screen flex justify-center item py-[100px]'>
        <div className='w-[90vw] flex-col'>
          <div className='flex gap-5'>
            <div className='w-1/3'>
              <Card className='Green flex items-center justify-center'>
                <h1 className='font-bold text-[48px] text-Dark-Blue-80 leading-normal text-center'>Pricing<br /> and <br /> API details</h1>
              </Card>
            </div>
            <div className='w-full flex flex-col gap-5'>
              <div className='flex gap-5'>
                <Card className={'w-3/6'}>
                  <h1 className='text-[120px] font-bold text-Dark-Blue-80 leading-[162px]'>₹0</h1>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Free Trading APIs</p>
                  <p className='text-Dark-Blue-80 text-base font-sm'>Explore cost-free APIs for <br /> seamless trades</p>
                </Card>
                <Card className={'w-full flex gap-6'}>
                  <div>
                    <h1 className='text-[120px] font-bold text-Dark-Blue-80 leading-[162px]'>₹0</h1>
                    <p className='text-lg font-bold text-Dark-Blue-80'>Market Data API</p>
                    <p className='text-Dark-Blue-80 text-base font-sm'>Real-time market insights for informed decisions</p>
                  </div>
                  <div>
                    <h1 className='text-[120px] font-bold text-Dark-Blue-80 leading-[162px]'>25</h1>
                    <p className='text-lg font-bold text-Dark-Blue-80'>F&O or Equity orders per month</p>
                  </div>
                </Card>
              </div>
              <Card>
                <div className='flex justify-between'>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Free Trading APIs</p>
                  <p className='text-lg font-bold text-Dark-Blue-80'>₹25 per orders</p>
                </div>
                <p className='text-Dark-Blue-80 font-sm'>Quick adjustments to adapt to market changes</p>
              </Card>
            </div>
          </div>
          <div className='flex gap-5 w-full mt-5'>
            <Card className={'w-full flex flex-col gap-5'}>
              <div >
                <div className='flex justify-between'>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Market Feed</p>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Real Time</p>
                </div>
                <p className='text-Dark-Blue-80 font-sm'>Live updates on market movements for smarter decisions</p>
              </div>
              <span className='w-full h-[1px] bg-White'></span>
              <div>
                <div className='flex justify-between'>
                  <p className='text-lg font-bold text-Dark-Blue-80'>API Response latency</p>
                  <p className='text-lg font-bold text-Dark-Blue-80'>100ms</p>
                </div>
                <p className='text-Dark-Blue-80 font-sm'>Lightning-fast API responses in just 100ms for seamless performance</p>
              </div>
            </Card>
            <Card className={'w-full flex flex-col gap-5'}>
              <div >
                <div className='flex justify-between'>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Order Placement</p>
                  <p className='text-lg font-bold text-Dark-Blue-80'>10 orders/Sec
                    500 order/Min</p>
                </div>
                <p className='text-Dark-Blue-80 font-sm'>Efficient and precise 10 order execution/ sec</p>
              </div>
              <span className='w-full h-[1px] bg-White'></span>
              <div>
                <div className='flex justify-between'>
                  <p className='text-lg font-bold text-Dark-Blue-80'>Max order limit</p>
                  <p className='text-lg font-bold text-Dark-Blue-80'>5000 orders/Day</p>
                </div>
                <p className='text-Dark-Blue-80 font-sm'>Controlled trading volumes for risk management</p>
              </div>
            </Card>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  )
}

export default Landing