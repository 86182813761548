import { useEffect, useState, useRef } from 'react'
import Card from '../../component/Card'
import MyAppsImg from './../../assets/MyApps.svg';
import Button from './../../component/Button'
import { useNavigate } from 'react-router-dom';
import { Dashboard_API_CALL } from '../../utilities/function';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import RegenerateImg from './../../assets/cached.svg'
import Pause from './../../assets/pause_circle.svg'
import Edit from './../../assets/border_color.svg'
import Play from './../../assets/play_circle.svg'
import DeleteSVG from './../../assets/delete.svg'
import { useOutletContext } from "react-router-dom";
import Loader from '../../component/Loader';
import CreateApp from './CreateApp';
import { useMediaQuery } from 'react-responsive';

// import Loader from '../../component/Loader';

const MyApp = () => {
    const [hasApp, setHasApp] = useState(true);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(null);
    const [makeAPICall, setMakeAPICall] = useState(false);
    const [editApp, setEditApp] = useState({
        openEditUI: false,
        data: null
    });
    const [loading, setLoading] = useState(false);
    const [openCreateApp, setOpenCreateApp] = useState(false);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 })

    const navigate = useNavigate();
    const createApp = () => {
        setOpenCreateApp(true);
    }
    const AppData = localStorage.getItem('data');
    const [setOpenDeleteKey, setAPISecret, setAPIKey, fetchAPI, setPauseKey, setFetchAPI, setAppStatus, setOpenReadMore, setReadMoreText] = useOutletContext();


    useEffect(() => {
        const api = async () => {
            const headers = {
                'Authorization': localStorage.accessToken,
                'Host': 'uat-developer.hdfcsky.com',
                'Content-Type': 'application/json'
            }
            const data = {
                clientId: localStorage.clientId,
                tenant: 'sky'
            }

            setLoading(true);

            const response = await Dashboard_API_CALL('/fetch-apps', 'POST', headers, null, data);
            console.log(response)
            if (response?.status !== 200) {
                setData([]);
                setLoading(false)
                setCount(0);
                setHasApp(false);
            } else if (response?.data?.data?.resp !== null && response?.data?.data?.resp !== undefined && response?.data?.data?.resp) {
                setData(response?.data?.data?.resp);
                setCount(response?.data?.data?.count);
                setHasApp(true);
                setTimeout(() => {
                    setLoading(false);
                }, 1000)
            }
        }

        api();
    }, [makeAPICall, fetchAPI])
    console.log(data)

    //APP DATA UI
    const AppDataUI = ({ name, apiKey, apiSecret, date, status, description, callbackUrl }) => {
        const [showApiKey, setShowApiKey] = useState(false);
        const [showApiSecret, setShowApiSecret] = useState(false);
        const [openMenu, setOpenMenu] = useState(false);
        const [regenerate, setRegenerate] = useState(false);

        const newRef = useRef(null);

        // Function to handle clicks outside the menu to close it
        const handleOutsideClick = (e) => {
            if (newRef.current && !newRef.current.contains(e.target)) {
                setOpenMenu(false);
            }
        };

        // Effect to add event listener when component mounts
        useEffect(() => {
            console.log(newRef)
            document.addEventListener('mousedown', handleOutsideClick);

            // Clean up event listener on unmount
            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, []);



        function formatDate(dateString) {
            const date = new Date(dateString);

            // Create an array of month names
            const monthNames = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            // Extract day, month, and year from the date object
            const day = date.getDate();
            const month = monthNames[date.getMonth()];
            const year = date.getFullYear();

            // Format the date as "18 May 2024"
            return `${day} ${month} ${year}`;
        }
        const renderDots = () => {
            let dots = [];
            const dot = <span className='p-1 h-2 w-2 bg-Grey-50 rounded-full'></span>
            if (isTabletOrMobile) {
                dots = new Array(10).fill(dot);
            } else {
                dots = new Array(16).fill(dot)
            }


            return dots;
        }
        const MenuItemClass = 'text-[14px] font-semibold text-Primary-Blue-100'
        const MenuItemDeleteClass = 'text-[14px] font-semibold text-[#E82727]'
        const regenerateAPI = async (apiKey, apiSecret) => {
            setRegenerate(true);
            const headers = {
                'Authorization': localStorage.accessToken,
                'Content-Type': 'application/json'
            }
            const data = {
                'clientId': localStorage.clientId,
                'apiKey': apiKey,
                'apiSecret': apiSecret
            }
            const response = await Dashboard_API_CALL('/regenerate-apps', 'post', headers, null, data);

            if (response.status !== '200' && response?.data?.meta?.statusCode !== 'OK') {
                setOpenMenu(false);
                setRegenerate(false);
                return;
            }

            setOpenMenu(false);
            setShowApiKey(false);
            setShowApiKey(false);

            setTimeout(() => {
                setRegenerate(false);
                setMakeAPICall({ data: response.data, notLoad: false });
                toast.success('API Key and Secret Regenerated');
            }, 1000)

        }



        return (
            <div
                className='w-full flex flex-col gap-5 lg:pb-6 max-lg:p-4 border-b-[1px] max-lg:mb-5 rounded-[20px] '>
                <div className='flex justify-between items-start '>
                    <div className='flex gap-5'>
                        <span className='bg-[#EBF5FF] border-[1px] rounded-md text-[rgba(0,0,0,0.8)] p-6 h-9 w-9 flex justify-center text-center items-center font-semibold'>
                            {
                                (name !== null && name !== undefined)
                                && name.substring(0, 1)
                            }
                        </span>
                        <div className='flex flex-col  gap-1' >
                            <h3 className='font-bold text-[14px]'>{name}</h3>
                            <div className='flex gap-3 items-end max-lg:items-center'>
                                {status === 'active' ?
                                    (<div className='flex font-bold items-center gap-1'>
                                        <span className='p-1 h-2 w-2 bg-[#028737] rounded-full'></span>
                                        <p className='text-green-600'>Active</p>
                                    </div>)
                                    :
                                    (<div className='flex font-bold items-center gap-1'>
                                        <span className='p-1 h-2 w-2 bg-[#E16C2A] rounded-full'></span>
                                        <p className='text-[#E16C2A]'>Paused</p>
                                    </div>)
                                }
                                {isTabletOrMobile ? (<p className='text-sm text-Dark-60'>{formatDate(date)}</p>)
                                    : (<>
                                        <span className='text-Dark-60'>•</span>
                                        <p className='text-sm text-Dark-60'>Created on {formatDate(date)}</p>
                                    </>
                                    )}

                            </div>
                            <p className='text-Dark-Blue-60 text-sm font-medium'>{(description.length > 15 && description !== null && description !== undefined) ?
                                <>
                                    {description?.substring(0, 15)}....  <span onClick={() => { setOpenReadMore(true); setReadMoreText(description); }} className='text-Primary-Blue-100 cursor-pointer font-bold'>Read More</span>
                                </>
                                : description}</p>
                        </div>
                    </div>
                    <div className='lg:relative'>
                        <button
                            onClick={() => setOpenMenu(!openMenu)}
                            className='border-[1px] flex justify-center items-center p-1 rounded-md cursor-pointer'>
                            <span className="material-symbols-outlined">
                                more_vert
                            </span>

                        </button>
                        {openMenu &&
                            <div
                                ref={newRef}
                                className={'shadow-md p-5 rounded-lg bg-[#fafafa] absolute max-lg:p-0 max-lg:flex max-lg:items-end  lg:right-0 lg:top-12 z-30 max-lg:bottom-0 max-lg:left-0 max-lg:w-full max-lg:h-full max-lg:bg-[rgba(0,0,0,0.2)] '}
                            >
                                <div
                                    onClick={() => { setOpenMenu(false) }}
                                    className='lg:w-[200px] max-lg:w-full flex flex-col gap-5 max-lg:justify-end max-lg:h-fit cursor-pointer max-lg:bg-white max-lg:p-5 max-lg:rounded-[20px]'>
                                    <div
                                        onClick={() => { regenerateAPI(apiKey, apiSecret) }}
                                        className='flex flex-row gap-2 items-center pb-5 border-b-[1px] cursor-pointer'>
                                        <img src={RegenerateImg} alt="" />
                                        <p className={MenuItemClass}>Regenerate Key</p>
                                    </div>
                                    {status === 'active' ?
                                        <div
                                            onClick={() => { setPauseKey({ message: 'Pause', status: 'inactive', modal: true }); setAPIKey(apiKey); setAPISecret(apiSecret) }}
                                            className='flex flex-row gap-2 items-center pb-5 border-b-[1px]  cursor-pointer'>
                                            <img src={Pause} alt="" />
                                            <p className={MenuItemClass}>Pause</p>
                                        </div>
                                        : <div
                                            onClick={() => { setPauseKey({ message: 'Resume', status: 'active', modal: true }); setAPIKey(apiKey); setAPISecret(apiSecret) }}
                                            className='flex flex-row gap-2 items-center pb-5 border-b-[1px]  cursor-pointer'>
                                            <img src={Play} alt="" />
                                            <p className={MenuItemClass}>Resume</p>
                                        </div>
                                    }
                                    <div
                                        onClick={() => {
                                            setEditApp(
                                                {
                                                    data: {
                                                        name,
                                                        callbackUrl,
                                                        description,
                                                        apiKey,
                                                        apiSecret
                                                    },
                                                    openEditUI: true
                                                }
                                            )
                                        }}
                                        className='flex flex-row gap-2 items-center pb-5 border-b-[1px]  cursor-pointer'>

                                        <img src={Edit} alt="" />
                                        <p className={MenuItemClass}>Edit</p>
                                    </div>
                                    <div
                                        onClick={() => { setOpenDeleteKey(true); setAPIKey(apiKey); setAPISecret(apiSecret); setAppStatus(status) }}
                                        className='flex flex-row gap-2 items-center pb-2  cursor-pointer'>
                                        <img src={DeleteSVG} alt="" />
                                        <p className={MenuItemDeleteClass}>Delete</p>
                                    </div>

                                </div>
                            </div>}
                    </div>
                </div>
                <div className='flex gap-5 max-lg:flex-col'>
                    {/* API KEY */}
                    <div className='flex w-full flex-col gap-4 p-5 rounded-md border-[1px] border-Grey-20'>
                        {regenerate ? <Loader message={'Regenerating...'} />
                            :
                            (<>
                                <p className='text-Dark-Blue-80 text-sm font-medium'>API Key</p>
                                <div className='flex gap-9 items-center'>
                                    {showApiKey ? (<>
                                        <p className='w-full text-sm text-Dark-Blue-80 font-medium'>{apiKey}</p>

                                        <span
                                            onClick={() => setShowApiKey(false)}
                                            className='material-symbols-outlined text-Primary-Blue-100 cursor-pointer'>
                                            visibility_off
                                        </span>

                                    </>
                                    )
                                        : (<>
                                            <div className='flex  gap-3 w-full'>
                                                {renderDots()}
                                            </div>
                                            <span
                                                onClick={() => setShowApiKey(true)}
                                                className="material-symbols-outlined text-Primary-Blue-100 cursor-pointer">
                                                visibility
                                            </span>
                                        </>
                                        )}


                                </div>
                                <CopyToClipboard
                                    text={apiKey}
                                    onCopy={() => toast.success('API Key Copied')}
                                >
                                    <button className='bg-Primary-Blue-10 px-6 py-1 rounded-md font-bold text-Primary-Blue-100 w-fit'>
                                        Copy
                                    </button>
                                </CopyToClipboard>
                            </>)}
                    </div>
                    {/* API Secret */}
                    <div className=' w-full flex flex-col gap-4 p-5 rounded-md border-[1px] border-Grey-20'>
                        {regenerate ? <Loader message={'Regenerating...'} /> :
                            (<>

                                <p className='text-Dark-Blue-80 text-sm font-medium'>API Secret</p>
                                <div className='flex gap-9 items-center'>
                                    {showApiSecret ? (<>
                                        <p className='w-full text-sm text-Dark-Blue-80 font-medium'>{apiSecret}</p>
                                        <span
                                            onClick={() => setShowApiSecret(false)}
                                            className='material-symbols-outlined text-Primary-Blue-100 cursor-pointer'>
                                            visibility_off
                                        </span>
                                    </>
                                    )
                                        : (<>
                                            <div className='flex w-full  gap-3'>
                                                {renderDots()}
                                            </div>
                                            <span
                                                onClick={() => setShowApiSecret(true)}
                                                className="material-symbols-outlined text-Primary-Blue-100 cursor-pointer">
                                                visibility
                                            </span>
                                        </>
                                        )}
                                </div>
                                <CopyToClipboard
                                    onCopy={() => toast.success('API Secret Copied')}
                                    text={apiSecret}
                                >
                                    <button className='bg-Primary-Blue-10 px-6 py-1 rounded-md font-bold text-Primary-Blue-100 w-fit'>
                                        Copy
                                    </button>
                                </CopyToClipboard>
                            </>)}
                    </div>
                </div>
            </div>
        )
    }
    console.log(count)

    if (!editApp.openEditUI && !openCreateApp) {
        return (
            loading ?
                <div className='w-full h-screen  flex items-center max-lg:items-start justify-center'>
                    <Card className={'w-[70vw] h-[70vh] max-lg:w-full max-lg:h-screen bg-black  flex items-center justify-center'}>
                        <Loader message='Fetching Apps' />
                    </Card>
                </div>

                : (<div className='w-full lg:h-screen flex items-center justify-center '>
                    <Card
                        // onClick={()=>{setOpenMenu(false)}}
                        className='w-[70vw] max-lg:w-full max-lg:h-[90vh] h-[70vh] flex flex-col gap-5 '>
                        <div className='flex lg:justify-between max-lg:items-center  border-b-[1px] pb-4'>
                            <h1 className='SubHeading max-lg:w-full'>
                                My Apps
                            </h1>

                            {(hasApp && count > 0) &&
                                <div className='flex gap-5 items-center'>
                                    <div className=''>
                                        <p className={`text-base max-lg:hidden font-bold ${count === 10 ? 'text-red-500' : 'text-Dark-Blue-80'} text-right`}>{count}/10</p>

                                        <p className='text-Dark-Blue-60 max-lg:hidden'>Free Apps used</p>
                                    </div>
                                    <p className={`text-base lg:hidden font-bold ${count === 10 ? 'text-red-500' : 'text-Dark-Blue-80'} text-right`}>{count}/10</p>

                                    {count !== 10 &&
                                        <button
                                            disabled={count === 10}
                                            onClick={createApp}
                                            className='text-center max-lg:w-full lg:border-dashed flex items-center lg:border-[1px] justify-center border-Primary-Blue-100 text-Primary-Blue-100 font-bold rounded-md py-2 lg:px-9'>
                                            + Create
                                        </button>
                                    }
                                </div>
                            }
                        </div>
                        {
                            (count > 0 && hasApp) ? (
                                <div className='overflow-x-hidden overflow-y-auto flex flex-col gap-10  max-lg:shadow-lg'>
                                    <div className='flex-col flex  gap-5 lg:pr-5'>
                                        {data.length > 0 && data.map((item, index) => (
                                            <AppDataUI
                                                index={index}
                                                key={index}
                                                status={item.status}
                                                date={item.updatedAt}
                                                apiKey={item.apiKey}
                                                callbackUrl={item.callBackUrl}
                                                apiSecret={item.apiSecret}
                                                name={item.displayName}
                                                description={item.productDescription}
                                            />
                                        ))}
                                    </div>


                                </div>

                            )


                                :
                                (<div className='w-full flex flex-col py-12 items-center justify-center gap-5'>
                                    <img className='w-[80px] h-[80px]' src={MyAppsImg} alt="" />
                                    <p className='text-base text-Dark-Blue-60 text-center'>No app is in the list. You can create upto 10 free apps.</p>
                                    <Button
                                        // onClick={()=>setOpenDeleteKey(true)}
                                        onClick={createApp}
                                        text="Create App"
                                        className={'w-1/3'} />
                                </div>)
                        }


                    </Card>
                </div>)
        )
    } else if (editApp.openEditUI && !openCreateApp) {
        return (<CreateApp isEdit={true} data={editApp.data} setEditApp={setEditApp} setMakeAPICall={setMakeAPICall} setOpenCreateApp={setOpenCreateApp} />)
    } else if (!editApp.openEditUI && openCreateApp) {
        return (<CreateApp isEdit={false} data={null} setEditApp={setEditApp} setMakeAPICall={setMakeAPICall} setOpenCreateApp={setOpenCreateApp} />)
    }
}

export default MyApp