import React, { useState, useEffect } from 'react'
import Card from '../../component/Card'
import Input from '../../component/Input'
import QR from './../../assets/qr.png';
// import Logo from './../../assets/sky-logo.svg'
import visibility from './../../assets/visibility.svg'
import visibility_off from './../../assets/visibility_off.svg'
import Button from '../../component/Button'
import Disclaimer from './Disclaimer'
import { Name, Logo } from '../../utilities/constants'
import { toast } from 'react-toastify'
import { API_CALL } from '../../utilities/function'
import { useMediaQuery } from 'react-responsive'

const Credentials = ({ setIsOTP, setTimer, setTwoFA, setLoginID, setMessage }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isCaptcha, setIsCaptcha] = useState({
        captchaIMG: '',
        captcha: false,
        captchaID: '',
        captchaAns: ''
    });

    const [merchDetails, setMerchDetails] = useState({
        name: '',
        logo: ''
    })

    useEffect(() => {
        const getImage = async () => {
            const response = await API_CALL('/merchant-details', 'get');

            if (!response.data?.merchantName && !response.data?.logo) {
                setMerchDetails(
                    {
                        name: '',
                        logo: ''
                    }
                )
            }

            setMerchDetails(
                {
                    name: response.data?.merchantName,
                    logo: response.data?.logo
                }
            )
        }

        getImage();
    }, [])


    const handleUsername = (event) => {
        setUsername(event.target.value)
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleLogin = async (event) => {
        event.preventDefault();
        if (username.trim().length === 0) {
            toast.error('Kindly Enter Valid Credentials');
            return;
        }
        let data = {
            'username': username.toLocaleUpperCase(),
        }
        let params = {
            username: username.toLocaleUpperCase(),
        }

        if (isCaptcha.captcha) {
            params = {
                ...params,
                'X-Captcha-Id': isCaptcha.captchaID,
                'X-Captcha-Answer': isCaptcha.captchaAns
            }

            data = {
                ...data,
                'X-Captcha-Id': isCaptcha.captchaID,
                'X-Captcha-Answer': isCaptcha.captchaAns
            }
        }

        const response = await API_CALL('/login-channel/validate', 'post', '', '', data);

        if (response === null || response === undefined || !response.hasOwnProperty('data')) {
            // toast.error("There has been an internal server issue");
            return;
        }

        if (response?.data?.alert === 'Password expired') {
            toast.warn(response.data.alert)
            return;
        }

        if (response?.data?.twoFAEnabled) {
            setIsOTP(true);
            setLoginID(response?.data?.loginId)
            setMessage(response?.data?.message)
            setTwoFA(response?.data?.twofa);
            setIsCaptcha(
                {
                    captchaIMG: '',
                    captcha: '',
                    captchaID: '',
                    captchaAns: ''
                }
            )
        }

        if (response.data.recaptcha) {
            const response = await API_CALL('/captcha', 'get');
            setIsCaptcha(
                {
                    captchaIMG: response.data.captcha,
                    captcha: true,
                    captchaID: response.data.captchaId,
                    captchaAns: ''
                }
            )
        }
    }

    return (
        <>
            {!isTabletOrMobile && <div className='flex flex-col bg-[url("./assets/LoginEllipse1.svg")] justify-center w-full gap-3'>
                <h1 className='leading-[57.2px] text-Dark-Blue-80 font-black text-[44px]'>
                    Welcome to,<br />
                    {Name}
                </h1>
                <p className='text-base text-Grey-80 font-bold'>
                    Investment Just got an Upgrade.<br />
                    Experience {Name}
                </p>
            </div>
            }
            <div className="w-full min-h-full bg-[url('./assets/MobileLoginEllipse1.svg'),url('./assets/MobileLoginEllipse2.svg')] bg-contain  bg-[bottom] bg-repeat-none">
                <Card className="shadow-lg min-h-full max-lg:min-h-screen flex-1 flex items-center  flex-col  py-6 max-lg:px-6 max-lg:py-10  h-full w-full">
                    <div className="flex items-center max-lg:items-start lg:w-5/6  max-lg:w-full flex-col gap-5">
                        <div className='mb-3'>
                            <img src={Logo} alt="" />
                        </div>
                        <img className='' width={125} src={merchDetails.logo} />
                        <div className="text-center max-lg:text-left w-full">
                            <h2 className="font-black text-Dark-Blue-80 text-[30px] max-lg:leading-[42px] w-full">
                                Login To<br className='lg:hidden' /> {merchDetails.name}
                            </h2>
                            <p className="text-Grey-50 font-semibold text-[13px] w-full">
                                Please enter Client ID / Mobile Number / Email ID to get started
                            </p>
                        </div>
                        <form
                            onSubmit={handleLogin}
                            className="flex flex-col gap-3 w-full ">
                            <Input
                                maxLength={30}
                                onChange={handleUsername}
                                value={username.toLocaleUpperCase()}
                                name={'Enter Client ID'}
                                placeholder='Enter Client ID / Mobile Number / Email ID' />
                            <div className='w-full relative'>
                                {/* <Input
                                    onChange={handlePassword}
                                    name={'Enter Password'}
                                    password={password}
                                    placeholder='Password'
                                    type={showPassword ? 'text' : 'password'} /> */}
                                {/* <div className='absolute flex items-center justify-center top-[5px] right-[8px] h-full'>
                                    {showPassword ?
                                        <img
                                            onClick={() => { setShowPassword(false) }}
                                            className='cursor-pointer'
                                            src={visibility} alt="" />
                                        :
                                        <img
                                            onClick={() => { setShowPassword(true) }}
                                            className='cursor-pointer'
                                            src={visibility_off} alt="" />
                                    }
                                </div> */}
                            </div>
                            <div>
                                {isCaptcha.captcha &&
                                    <div className='flex gap-3'>
                                        <div className=''>
                                            <img src={`data:image/png; base64,${isCaptcha.captchaIMG}`} alt="Captcha" />
                                        </div>
                                        <div className='w-full'>
                                            <Input
                                                placeholder='Enter Captcha Code'
                                                onChange={(event) => {
                                                    setIsCaptcha((item) => ({ ...item, captchaAns: event.target.value }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <Button text={'Get Started'} />
                            {/* <p className='text-center text-Grey-50 max-lg:mt-5 max-lg:mb-7'>Or</p> */}

                        </form>
                    </div>
                    <div className="h-full flex items-end">
                        <Disclaimer />

                    </div>
                </Card>
            </div>
        </>
    )
}

export default Credentials