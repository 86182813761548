import React, { useState } from 'react'
import Card from '../../component/Card'
import Input from '../../component/Input'
import Button from '../../component/Button'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Dashboard_API_CALL } from '../../utilities/function'
import { useMediaQuery } from 'react-responsive'
import goBack from './../../assets/chevron_left.svg'

const CreateApp = ({ isEdit, data, setOpenCreateApp, setEditApp, setMakeAPICall }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
    const navigate = useNavigate();

    const [details, setDetails] = useState({
        name: isEdit ? data.name : '',
        callbackUrl: isEdit ? data.callbackUrl : '',
        productDescription: isEdit ? data.description : ''
    });
    console.log(details)
    console.log(data)
    const handleChange = (event) => {
        setDetails((items => ({ ...items, [event.target.name]: event.target.value })))
    }

    console.log(details)

    const handleClick = async (event) => {
        event.preventDefault()
        const headers = {
            'Host': 'uat-developer.hdfcsec.com',
            'Authorization': localStorage.accessToken,
            'Content-Type': 'application/json'
        }
        console.log(headers);
        let requestData = details;
        const specialChar = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
        const emptySpace = /[ ]/
        if (specialChar.test(details.name)) {
            toast.warn('App Name Cannot Have Special Characters');
            return;
        }

        if (!details.callbackUrl.includes('https://') || emptySpace.test(details.callbackUrl)) {
            toast.warn('Invalid Redirect URL');
            return;
        }

        if (isEdit) {
            requestData = { ...details, clientId: localStorage.clientId, apiKey: data.apiKey, apiSecret: data.apiSecret }
        }
        const response = await Dashboard_API_CALL(`${isEdit ? '/update-im-app-details' : '/create-app'}`, 'POST', headers, null, requestData);
        console.log(response)
        if (response?.status !== 200) {
            return;
        }
        if (!isEdit) {
            setOpenCreateApp(false);
        } else if (isEdit) {
            setEditApp({
                openEditUI: false,
                data: null
            })
        }
        // navigate('/dashboard/apps');
        setMakeAPICall(response.data)
        toast.success(`App Has Been ${isEdit ? 'Updated' : 'Created'}`);

    }

    const clickBack = () => {
        if (!isEdit) {
            setOpenCreateApp(false);
        } else if (isEdit) {
            setEditApp({
                openEditUI: false,
                data: null
            })
        }
    }

    return (
        <section className='w-full h-screen gap-2 flex lg:items-center lg:justify-center max-lg:items-start'>
            <div className='flex gap-3 max-lg:h-full max-lg:w-full'>
                <Card className='w-[50vw] max-lg:w-full min-h-[60vh] max-lg:h-full  flex flex-col gap-5 lg:p-0 '>
                    <div className='lg:pl-5 lg:pt-5 flex gap-3'>
                        <img
                            className='cursor-pointer'
                            onClick={clickBack}
                            src={goBack} alt="" />
                        <h1 className='SubHeading'>
                            {isEdit ? "Update App" : "New App"}
                        </h1>
                    </div>
                    <form onSubmit={handleClick}>

                        <div
                            className='w-full lg:pl-5  flex flex-row  gap-5 border-b-[1px] border-t-[1px] border-gray-200 max-lg:flex-col'>
                            <div className='w-1/2 max-lg:w-full lg:h-[40vh] flex flex-col gap-5 lg:border-r-[1px]  border-gray-200 lg:pr-5'>
                                <div
                                    className='pt-5'
                                >

                                    <Input
                                        maxLength={'25'}
                                        isRequired={true}
                                        onChange={handleChange}
                                        name={'name'}
                                        placeholder={'Enter App Name'}
                                        className={'w-full'}
                                        label={'App Name'}
                                        iswhite={true}
                                        value={details.name}
                                    />
                                </div>
                                <div>
                                    <Input
                                        isRequired={true}
                                        onChange={handleChange}
                                        name={'callbackUrl'}
                                        value={details.callbackUrl}
                                        placeholder={'Paste URL Here'}
                                        className={'w-full'}
                                        label={'Redirect URL'}
                                        iswhite={true}
                                    />
                                    {/* <p className='text-[10px] mt-2'>Also called Return or Target URL. Must be HTTPS. <span className='text-Primary-Blue-100'>Learn More</span></p> */}
                                </div>
                            </div>
                            <div className='w-1/2 max-lg:w-full lg:h-[40vh] max-lg:h-[20vh] flex flex-col gap-2  lg:pr-5 lg:pt-5'>
                                <label className='text-sm  text-Dark-Blue-60 font-bold'>Description</label>
                                <textarea
                                    name='productDescription'
                                    required
                                    maxlength="50"
                                    value={details.productDescription}
                                    onChange={handleChange}
                                    className='outline-none border border-1 py-2 px-2 max-lg:py-4 rounded-md h-2/4 placeholder:text-Grey-50 max-lg:h-full'
                                    placeholder={'Write a small description for future reference'}
                                >
                                </textarea>
                            </div>
                        </div>
                        <div className='pb-5 pt-5 flex justify-center w-full'>
                            <Button
                                type={'submit'}
                                className={'w-3/5 max-lg:w-full'}
                                text={isEdit ? "Update" : "Create"}
                            />
                        </div>

                    </form>
                </Card>
                {!isTabletOrMobile && <Card className='w-[25vw] min-h-[60vh] flex flex-col gap-5 lg:p-0'>
                    <div className='lg:pl-5 lg:pt-5 border-b-[1px] border-gray-200 pb-5'>
                        <h1 className='SubHeading'>
                            APIs in this app
                        </h1>
                    </div>
                    <div className='w-full flex flex-col items-start pl-5 pr-5 justify-start gap-5'>
                        <div className='w-full flex items-start gap-2'>
                            <span className='material-symbols-outlined text-lg'>done</span>
                            <div className='flex-col flex gap-1'>
                                <h2 className='text-[16px] text-Dark-Blue-80'>Trading API</h2>
                                <p className='text-[13px] text-Dark-Blue-60 '>Helps you place orders and manage Stocks, Future and Options</p>
                            </div>
                        </div>
                        {/* <div className='w-full flex items-start gap-2'>
                            <span className='material-symbols-outlined text-lg'>done</span>
                            <div className='flex-col flex gap-1'>
                                <h2 className='text-[16px] text-Dark-Blue-80'>Live Broadcast API</h2>
                                <p className='text-[13px] text-Dark-Blue-60 '>Get live prices of Stocks, Future and Options through API and Web Socket</p>
                            </div>
                        </div> */}
                        <div>

                        </div>
                    </div>
                </Card>}
            </div>
        </section>
    )
}

export default CreateApp