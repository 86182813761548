import { useEffect, useState } from "react"
import Button from "../../component/Button"
import Card from "../../component/Card"
import { AuthoriseContent } from "../../utilities/constants"
import { API_CALL } from "../../utilities/function"
import { Logo } from "../../utilities/constants"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"
import Fade from "../../component/Fade"

const Authorisation = ({ requestToken, termsAndConditions, setIsOTP, setAuthorize, setOpenDisclaimer, setParams }) => {
    const [merchDetails, setMerchDetails] = useState({
        name: '',
        logo: ''
    })
    const navigate = useNavigate();

    // const parsedTNC = JSON.parse(termsAndConditions)
    const [tnc, setTnc] = useState(termsAndConditions);

    useEffect(() => {
        const getImage = async () => {
            const response = await API_CALL('/merchant-details', 'get');

            if (response) {
                setMerchDetails(
                    {
                        name: response.data.merchantName,
                        logo: response.data.logo
                    }
                )
            }
        }

        getImage();
    }, [])


    const authorise = async (choice) => {
        const params = {
            consent: choice,
            request_token: requestToken
        }
        if (choice) {
            setParams(params)
            setOpenDisclaimer(true)
        } else {
            const response = await API_CALL('/authorise', 'get', '', params);

            setIsOTP(false)
            setAuthorize(false)
        }

    }

    return (
        <div className="lg:w-4/6 min-h-full flex items-center justify-center ">
            
                <Card className='lg:min-h-full max-lg:h-fit max-lg:w-full flex flex-col items-center w-[1024px] justify-center'>
                    <div className="GradientBorder min-h-full ">
                        <div className='AuthoriseCard h-full p-[20px] justify-center self-center  flex flex-col gap-5 items-center '>
                            <h3 className="text-base max-lg:text-center text-Dark-Blue-80 font-black">
                                Permission required to authorise the app
                            </h3>
                            <div className="flex flex-col items-center justify-center gap-2 text-center">
                                {merchDetails?.name?.trim().length > 0 &&
                                    <p className="text-Dark-Blue-80 font-medium">{merchDetails?.name}</p>
                                }
                                {merchDetails?.logo?.trim().length > 0 &&
                                    <div className="flex">
                                        <div className="bg-white mr-[-5px] w-[50px] h-[50px] z-5 flex items-center justify-center rounded-full p-2 ">
                                            <img
                                                className="h-[60px] w-[60px] object-contain"
                                                src={Logo}
                                                alt="Merch_Logo"
                                            />
                                        </div>
                                        <div className="bg-gray-100 z-1 w-[50px] h-[50px] flex items-center justify-center rounded-full p-3 ">
                                            <img
                                                className="h-[40px] w-[40px]"
                                                src={merchDetails.logo}
                                                alt="Merch_Logo"
                                            />
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className="h-full w-full flex-col justify-center flex gap-4">
                                {tnc?.content.length > 0 &&
                                    tnc.content.map(item =>
                                        <div className="flex items-center gap-4 whitespace-pre-wrap">
                                            <span className='material-symbols-outlined text-base text-Primary-Blue-100'>
                                                check_circle
                                            </span>
                                            {item.body}
                                        </div>)}
                            </div>
                            <div className="w-full text-center h-full flex flex-col gap-4 justify-end">
                                <p className="text-[12px] text-left self-center leading-[18px]">
                                    By clicking authorise, the user agrees that his/her
                                    personal<br className="max-lg:hidden" />  information may be shared with third party service providers/<br className="max-lg:hidden" />
                                    Affiliates for the purpose of delivering service to you
                                </p>
                                <Button onClick={() => { authorise(true) }} className='w-full' text={'Authorise'} />
                                <p onClick={() => { authorise(false); }} className="font-bold text-Primary-Blue-100 cursor-pointer">Cancel</p>
                            </div>

                        </div>
                    </div>
                </Card>
        </div>
    )
}

export default Authorisation