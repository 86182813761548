import zero from './../assets/zero.svg';
import lightning from './../assets/lightning.svg';
import linked from './../assets/linked.svg';
import court from './../assets/court.svg';
import settings from './../assets/settings.svg';
import charts from './../assets/charts.svg';
import Service from './../assets/Service.png';
import Individual from './../assets/Individual.png';
import Fintech from './../assets/Fintech.png';
import HDFCSKYlogo from './../assets/sky-logo.svg';
import InvestRightLogo from './../assets/InvestRight.png'
import NavbarHDFCSkyLogo from './../assets/Logo.svg';


export const Name = "HDFC Sky";


export const NavbarLogo = NavbarHDFCSkyLogo;

export const Logo = HDFCSKYlogo;

export const InvestRight_API_URL = 'https://uat-developer.hdfcsec.com';
export const Sky_API_URL_UAT = 'https://uat-developer.hdfcsky.com/oapi/v1';
export const Sky_API_URL_Production = 'https://developer.hdfcsky.com/oapi/v1';
export const InvestRight_Production = 'https://developer.hdfcsec.com';

export const AuthoriseContent = [
    'Place,Modify and cancel order',
    'Access and view your holding , positons and transaction history',
    'View account balances and margins',
    'F&O, Option chain, Questionnaire and rating',
    'Access and view your profile'
];

export const disclaimerContent = [
    "9 out of 10 individual traders in equity Futures and Options Segment, incurred net losses.",
    "On an average, loss makers registered net trading loss close to ₹50,000.",
    "Over and above the net trading losses incurred, loss makers expended an additional 28 % of net trading losses as transaction costs.",
    "Those making net trading profits, incurred between 15 % to 50 % of such profits as transaction costs."
]

export const TabsContent = [
    {
        img: Individual,
        imgHeading: <span>Individual Trader<br className='max-lg:hidden' /> or Developer</span>,
        contentHeading: <span>Maximize Trading Potential: Unveiling API <br className='max-lg:hidden' /> Advantages for Traders & Developers</span>,
        content: [
            'Zero cost APIs allows you to experiment freely.',
            'Simple to use APIs allows developers of any skill level to connect effortlessly across a growing network of apps.',
            'Low Latency customisable APIs allows individuals to build fast, secure and modular trading systems'
        ]
    }, 
    {
        img: Fintech,
        imgHeading: <span>Fintechs and <br /> Startups</span>,
        contentHeading: <span>Accelerate Innovation: Embrace our API for<br className='max-lg:hidden' /> Startups & Fintech</span>,
        content: [
            'Open your door to live market broadcasts, executing orders in real time, managing the user portfolio and many more.',
            'As a fintech startup, you can access our APIs, free of cost to build a full fledged innovative trading and investment platform.',
            // <span className='hidden'>To know more about our API, Features & Access, you can reach out to us at <span className='text-Primary-Blue-100 font-medium'>openapi.care@hdfcsec.com</span></span>
        ]
    }
];

export const FeatureCardContent = [
    {
        heading: 'Free APIs for All',
        text: 'Free SKY Trading APIs for traders, developers, and fintech startups.',
        img: zero
    },
    {
        heading: 'Fast, Simple and Seamless',
        text: 'Get lightning-fast and easy to use trading execution',
        img: lightning
    },
    {
        heading: 'Reliability You Can Trust',
        text: "Trade confidently, backed by SKY API's sturdy and dependable infrastructure.",
        img: court
    },
    {
        heading: 'Real-time Market Insights',
        text: 'Get market trends and opportunities with real-time data.',
        img: charts
    },
    {
        heading: 'User-Friendly Integration',
        text: 'User-friendly interface for integrations, catering to developers of all levels.',
        img: settings
    },
    {
        heading: 'Connected Apps Ecosystem',
        text: 'Access a growing network of apps optimized for SKY API',
        img: linked
    }
]

export const FeatureCardContent2 = [
    {
        heading: 'Trading API',
        subheading: 'Effortless trade execution',
        text: 'Trade easily with smooth transactions and precise execution for your investments.'
    },
    {
        heading: 'Position API',
        subheading: 'Transparent portfolio visibility',
        text: 'See your investments clearly and gain visibility and control for strategic portfolio moves.'
    },
    {
        heading: 'Holding API',
        subheading: 'Efficient asset tracking',
        text: 'Manage holdings easily and get an organized view of your assets for efficient monitoring'
    },
    {
        heading: 'Market Data API',
        isNew: true,
        subheading: 'Real-time market insights',
        text: 'Explore the market using real-time insights, trends, and empowered decision-making in trading.'
    },
    {   
        isNew: true,
        heading: 'GTT Order API',
        subheading: 'Flexible conditional orders',
        text: 'Customize and execute orders with precise, adaptable and tailored trading strategies '
    },
    {
        heading: 'Websockets',
        isNew: true,
        subheading: 'Continuous real-time market updates',
        text: 'Stay updated with continuous live data for informed and timely decision with real-time market updates'
    },
];