import { useState, useRef } from "react"
import Button from "../../component/Button"
import Card from "../../component/Card"
import Input from "../../component/Input"
// import Logo from "./../../assets/sky-logo.svg"
import Credentials from "./Credentials"
import Authorisation from './Authorisation'
import OTP from "./OTP"
import { Logo } from "../../utilities/constants"
import Footer from "../../component/Footer"
import Disclaimer from "../../component/Disclaimer"
import SecurityCode from "./SecurityCode"
import Fade from "../../component/Fade"

const Login = () => {
  const [isOTP, setIsOTP] = useState(false);
  const [isAuthorise, setAuthorize] = useState(false);
  const [message, setMessage] = useState('')
  const [isSecurity, setIsSecurity] = useState(false);
  const [requestToken, setRequestToken] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [loginID, setLoginID] = useState('');
  const [twoFA, setTwoFA] = useState(null);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const [params, setParams] = useState(null)
  const [callbackUrl, setCallbackUrl] = useState(null);
  const [loginId, setLoginId] = useState(null)

  // console.log(twoFA , requestToken)
  return (
    <>
      <section className='h-screen min-h-screen LoginBG w-full flex max-lg:items-start justify-center items-center lg:py-10'>
        <div className='w-[1024px] min-h-full flex relative justify-center'>
          <img
            width={115}
            className="absolute left-0 max-lg:hidden"
            src={Logo}
            alt="" />

          {(!isOTP && !isAuthorise && !isSecurity) &&

            <Credentials
              setMessage={setMessage}
              setIsOTP={setIsOTP}
              setLoginID={setLoginID}
              setTwoFA={setTwoFA}
            />
          }
          {
            isOTP &&

            <OTP
              message={message}
              setCallbackUrl={setCallbackUrl}
              twoFA={twoFA}
              setTermsAndConditions={setTermsAndConditions}
              setRequestToken={setRequestToken}
              setIsSecurity={setIsSecurity}
              setAuthorize={setAuthorize}
              loginID={loginID}
              setIsOTP={setIsOTP}
            />
          }
          {
            isAuthorise &&

            <Authorisation
              setParams={setParams}
              setOpenDisclaimer={setOpenDisclaimer}
              setIsOTP={setIsOTP}
              setAuthorize={setAuthorize}
              termsAndConditions={termsAndConditions}
              requestToken={requestToken} />
          }
          {
            isSecurity &&

            <SecurityCode
              setCallbackUrl={setCallbackUrl}
              setOpenDisclaimer={setOpenDisclaimer}
              setTermsAndConditions={setTermsAndConditions}
              setRequestToken={setRequestToken}
              setIsSecurity={setIsSecurity}
              setAuthorize={setAuthorize}
              loginID={loginID}
              setIsOTP={setIsOTP}
            />
          }

        </div>
        {
          openDisclaimer &&
          <div className="w-full absolute top-0 left-0">
            <Disclaimer
              callbackUrl={callbackUrl}
              params={params}
              requestToken={requestToken}
              isAuthorize={isAuthorise}
              setAuthorize={setAuthorize}
              setOpenDisclaimer={setOpenDisclaimer}
            />
          </div>
        }
      </section>

      <Footer />
    </>
  )
}

export default Login