import React from 'react'

const Button = ({ text, onClick, className, outlined, type , children,isDisabled}) => {
    return (
        <button
            type={type}
            disabled={isDisabled}
            className={`${className} 
            py-2 ${outlined ? 'bg-transparent text-Primary-Blue-100 border-[1px] border-solid border-Primary-Blue-100' : 'bg-Primary-Blue-100 text-white '} 
            rounded-md text-[16px] max-lg:py-4 font-bold`}
            onClick={onClick}>
            {text}
            {children}
        </button>
    )
}

export default Button