import React from 'react'
import Card from '../../component/Card'
import LoginSVG from './../../assets/Login.svg'
import GoogleLogo from './../../assets/google.png'
import Captcha from './../../assets/captcha.png'
import Vector from './../../assets/LogoVector.svg';
import { Logo } from '../../utilities/constants'
import Input from '../../component/Input'
import Button from '../../component/Button'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Dashboard_API_CALL } from '../../utilities/function'
import { toast } from 'react-toastify'
import OTP from '../Login2_with_OTP/IndiOTP'
import { motion } from 'framer-motion'

import World from './../../assets/World.png'
import SecurityCodeIM from './SecurityCodeIM'

const IndividualLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [clientId, setClientId] = useState('')
  const [password, setPassword] = useState('');
  const [isOTP, setIsOTP] = useState(false);
  const [isSecurity, setIsSecurity] = useState(false);
  const [message, setMessage] = useState('')
  const handleUsername = (event) => {
    setUsername(event.target.value)
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)
  }

  const [isCaptcha, setIsCaptcha] = useState({
    captchaIMG: '',
    captcha: false,
    captchaID: '',
    captchaAns: ''
  });

  const handleLogin = async (event) => {
    // navigate('/dashboard/apps')
    event.preventDefault()
    if (username.trim().length === 0) {
      toast.error('Kindly Enter Valid Credentials');
      return;
    }
    let data = {
      'username': username.toLocaleUpperCase(),
    }
    let params = {
      username: username.toLocaleUpperCase(),
    }

    if (isCaptcha.captcha) {
      params = {
        ...params,
        'X-Captcha-Id': isCaptcha.captchaID,
        'X-Captcha-Answer': isCaptcha.captchaAns
      }

      data = {
        ...data,
        'X-Captcha-Id': isCaptcha.captchaID,
        'X-Captcha-Answer': isCaptcha.captchaAns
      }
    }



    const response = await Dashboard_API_CALL('/im/login/validate', 'post', '', params, data);

    if (response === null || response === undefined || !response.hasOwnProperty('data')) {
      // toast.error("There has been an internal server issue");
      return;
    }

    if (response.data.alert === 'Password expired') {
      toast.warn(response.data.alert)
      return;
    }

    if (response.data.twoFAEnabled) {
      setIsOTP(true);
      setClientId(response.data.loginId)
      setMessage(response?.data?.message)
      // setLoginID(response.data.loginId)
      // setTimer(response.data.timer);
      // setTwoFA(response.data.twofa);
      // setQuestionId(response.data.questionId)
      setIsCaptcha(
        {
          captchaIMG: '',
          captcha: '',
          captchaID: '',
          captchaAns: ''
        }
      )
    }

    if (response.data.recaptcha) {
      const response = await Dashboard_API_CALL('/im/captcha', 'get');
      setIsCaptcha(
        {
          captchaIMG: response.data.captcha,
          captcha: true,
          captchaID: response.data.captchaId,
          captchaAns: ''
        }
      )
    }
  }

  useEffect(() => {
    if ((localStorage.accessToken !== null || localStorage.accessToken !== undefined) && localStorage.imIsLoggedIn) {
      navigate('/oapi/v1/dashboard/apps')
    }
  }, [])



  return (
    <section className='min-h-screen lg:p-12 w-full flex flex-col items-center justify-center bg-loginBackground bg-cover'>
      <Card
        className={'lg:w-[70vw] max-lg:p-0 max-lg:bg-white max-lg:rounded-none max-lg:w-full flex lg:p-0 flex-1'}>
        <div className='w-3/5 max-lg:w-full p-5 flex flex-col gap-5'>
          {isOTP &&
            (<div>
              <OTP
                message={message}
                clientId={clientId}
                setIsOTP={setIsOTP}
                setIsSecurity={setIsSecurity} />
            </div>)
          }
          {isSecurity &&

            (
              <div>
                <SecurityCodeIM clientId={clientId} />
              </div>
            )
          }
          {
            (!isOTP && !isSecurity) &&
            <>
              <div className='flex gap-3'>
                <img className='lg:hidden' src={Vector} alt="Sky-Vector" />
                <h2 className='text-[32px] font-bold text-Dark-Blue-80'>Login</h2>
              </div>
              <form
                onSubmit={handleLogin}
                className='flex flex-col gap-5 w-3/4 max-lg:w-full'>
                <Input
                  maxLength={30}
                  value={username.toLocaleUpperCase()}
                  onChange={handleUsername}
                  iswhite={true}
                  label="Enter Client ID /Email ID/ Mobile No." />
                {/* <Input
                  maxLength={30}
                  onChange={handlePassword}
                  iswhite={true}
                  label="Enter Password"
                  type={'password'} /> */}
                {/* <div className='flex gap-5 items-center'>
            <div className='w-2/6'>
              <img className='w-full h-[42px] object-fill' src={Captcha} alt="" />
            </div>
            <div className='w-4/6'>
              <Input iswhite={true} placeholder={'Enter Captcha'} />
            </div>
          </div> */}
                <div className='flex gap-5'>
                  <Button
                    type='submit'
                    className={'w-full text-sm'}
                    text={'Proceed to HDFC SKY'} />
                  <Button
                    type={'button'}
                    onClick={() => { window.open('https://hdfcsky.com/sky/?to=SIGN_UP&&utm_Source=organic&utm_Medium=Website&utm_Campaign=Homepage&utm_Term=HSLNew&utm_Content=open_trading_account_Generic') }}
                    outlined={true}
                    className={'w-2/4 text-sm'}
                    text={'Sign Up'} />
                </div>
                {/* <div className='flex justify-between'>
                <p className='text-sm font-medium text-Primary-Blue-100 cursor-pointer'>
                  Login With OTP
                </p>
                <p className='text-sm font-medium text-Primary-Blue-100 cursor-pointer'>
                  Forgot Password ?
                </p>
              </div> */}
                {/* <div className='flex flex-col gap-3'>
            <p className='text-sm text-Dark-Blue-60'>
              Or Log in Using
            </p>
            <button className='border-[1px] flex justify-center items-center rounded-md h-[42px] w-[113px] border-Grey-50'>
              <img src={GoogleLogo} alt="" />
            </button>
          </div>
          <div className='grid grid-cols-3 text-[12px] text-Dark-Blue-60 gap-3 w-full flex-grow'>
            <p>App version 1.0.0.0</p>
            <p>Build version 1.0.0.0</p>
            <p>NSE version 1.0.0.0</p>
            <p>BSE version 1.0.0.0</p>
            <p>MCX version 1.0.0.0</p>
          </div> */}
              </form>
            </>
          }

        </div>
        <div className='bg-loginFrame max-lg:hidden bg-cover w-2/5 gap-7 flex flex-col justify-start items-center py-9 px-4'>
          <img className='w-1/3' src={Logo} alt="" />
          <p className='text-base text-Dark-Blue-60 font-medium text-center'>Investing just got an upgrade, <br /> Experience HDFC SKY</p>
          <img src={LoginSVG} alt="" />
          <div>
            <img width={250} src={World} alt="world Image" />
          </div>
        </div>

      </Card>
    </section>
  )
}

export default IndividualLogin