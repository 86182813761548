

const Input = ({ placeholder, type, label, name, onChange ,value , iswhite , isRequired , maxLength}) => {
    return (
        <div className='relative flex flex-col gap-2'>
            <label className='text-sm  text-Dark-Blue-60 font-bold' htmlFor={name}>{label}</label>
            <input
                maxLength={maxLength}
                required={isRequired}
                value={value}
                onChange={onChange}
                className={`outline-none border border-1 focus:border-green-600 ${iswhite?'bg-white':'bg-transparent'} ${iswhite?'border-gray-200':'border-gray-300'} w-full py-2 px-2 max-lg:py-4 rounded-md placeholder:text-Grey-50`} placeholder={placeholder} name={name} type={type} />
        </div>
    )
}

export default Input