import React from 'react'
import { motion } from 'framer-motion'

export const fade = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 1 }
}


const Fade = ({ children }) => {
    return (
        <motion.div
            animate={fade.animate}
            initial={fade.initial}
            transition={fade.initial}
            className='w-full'
        >
            {children}
        </motion.div>
    )
}

export default Fade