import { useState, useEffect } from 'react'
import Card from '../../component/Card';
import { API_CALL } from '../../utilities/function';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import Fade from '../../component/Fade';
import { SetSecurityCode } from './../Login_with_OTP/OTP'



const SecurityCode = ({ twoFA, setAuthorize, setIsOTP, setRequestToken, loginID, setTermsAndConditions, setCallbackUrl, setIsSecurity, setOpenDisclaimer }) => {
    const otpArr = new Array(4).fill('')
    const [otp, setOtp] = useState(otpArr);
    const [mobileOTP, setMobileOTP] = useState([]);
    const [seconds, setSeconds] = useState(30);
    const [resend, setResend] = useState(false);
    const [question, setQuestion] = useState('');
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });



    const newArr = new Array(9).fill(0);

    useEffect(() => {
        if (!isTabletOrMobile) {
            document.getElementById(`otp-input-0`).focus()
        }
    }, [])


    //For Sending OTP API Call
    useEffect(() => {
        const enteredOtp = otp.join('');

        //Handling OTP Request
        const sendAns = async (answer) => {
            const data = {
                'answer': answer,
                'question_id': question.question_id
            }
            const response = await API_CALL('/twofa/validate', 'post', '', '', data)
            // console.log(response)
            // if (!response.hasOwnProperty('data')) {
            //     toast.error(response);
            // }
            if (!response.hasOwnProperty('data')) {
                if (!isTabletOrMobile) {
                    document.getElementById(`otp-input-0`).focus()
                }
            }
            if (response?.data?.authorised === false) {
                if (response?.data?.requestToken !== null && response?.data?.requestToken !== undefined) {
                    setIsSecurity(false);
                    setAuthorize(true);
                    setTermsAndConditions(response.data.termsAndConditions);
                    setRequestToken(response.data.requestToken);
                    setCallbackUrl(response.data.callbackUrl);
                }
            }
            else if (response?.data?.authorised) {
                if (response?.data?.requestToken !== null && response?.data?.requestToken !== undefined) {
                    setAuthorize(false);
                    setOpenDisclaimer(true);
                    setTermsAndConditions(response.data.termsAndConditions);
                    setRequestToken(response.data.requestToken);
                    setCallbackUrl(response.data.callbackUrl);
                }

            } else {
                toast.error("You've Entered An Invalid PIN");
                if (!isTabletOrMobile) {
                    document.getElementById(`otp-input-0`).focus()
                }
                // toast.error("You've entered a Wrong OTP");
                setOtp(otpArr)
            }
        };


        if (enteredOtp.trim().length === otp.length) {
            sendAns(enteredOtp);
        }
    }, [otp]);


    //For Resending OTP
    useEffect(() => {


        if (twoFA === null || twoFA === undefined) {
            // toast.error('Problem in getting 2FA Code');
            setQuestion({
                question_id: 0,
                question: "Enter OTP"
            })
            return;
        }

        setQuestion({
            question_id: 0,
            question: twoFA.questions[0].question
        })

    }, []);

    //Timer
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 0) {
                    clearInterval(interval);
                    setResend(true)
                    return 0;
                }
                else if (prevSeconds < 0) {
                    setResend(false);
                    clearInterval(interval);
                }
                else {
                    return prevSeconds - 1;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);



    //Handling OTP for Mobile
    const handleButtonClick = (item) => {

        let arr = [...otp];
        if (arr[0] !== "") {
            return;
        }
        arr.push(item);
        arr.shift();

        setOtp(arr)

    }

    const handleBackspace = () => {
        let arr = [...otp];

        arr.pop();
        arr.unshift('')
        setOtp(arr);

    }

    //Handling OTP Input for Desktop
    const handleChange = (index, value) => {
        if (isNaN(value) || value.length > 1) {
            return;
        }

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (index < otp.length - 1 && value !== '') {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (index, value, key) => {
        if (key === 'Backspace' && value === '') {

            if (index > 0) {
                const previousInput = document.getElementById(`otp-input-${index - 1}`);
                if (previousInput) {
                    previousInput.focus();
                }
            }
        }
    };




    return (
        <>
            <div className="lg:w-3/6 max-lg:w-full min-h-full flex items-center justify-center">
                <Fade>
                    <Card className='max-lg:h-screen  w-full flex flex-col lg:items-center max-lg:items-start'>
                        <div className="w-full max-lg:mb-5 pt-10">
                            <span onClick={() => setIsSecurity(false)} className="material-symbols-outlined text-Dark-Blue-80 cursor-pointer">
                                arrow_back
                            </span>
                        </div>
                        <div className="pb-10 flex flex-col gap-5 max-lg:w-full">
                            <div className="text-left">
                                <h2 className="font-black text-Dark-Blue-80 text-[30px]">
                                    Enter the Pin
                                </h2>
                                {/* <p className="text-Grey-50 flex items-center">
                                Send to <b className='ml-1 text-bold text-Dark-Blue-80'>{loginID}</b>
                                <span
                                    onClick={() => setIsOTP(false)}
                                    className=" ml-1 material-symbols-outlined text-base text-Primary-Blue-100 cursor-pointer" >edit</span>
                            </p> */}
                            </div>
                            {
                                !isTabletOrMobile &&
                                <div className="flex w-full justify-between gap-5">
                                    {otp.map((digit, index) => {
                                        return (
                                            <input
                                                key={index}
                                                type="text"
                                                className={`outline-none border ${otp[index].length > 0 ? 'border-green-400 border-2' : ' border-grey-300'}  w-[58px] h-[58px] border-1 bg-transparent  p-2 text-center rounded-md placeholder:text-Grey-50`}
                                                id={`otp-input-${index}`}
                                                maxLength={1}
                                                autoComplete='off'
                                                value={digit}
                                                onChange={(e) => handleChange(index, e.target.value)}
                                                onKeyDown={(e) => handleKeyDown(index, e.target.value, e.key)}
                                            />
                                        )
                                    })}
                                </div>
                            }
                            {isTabletOrMobile &&
                                <div className='w-full flex justify-center max-lg:mt-8'>
                                    <div className="flex flex-wrap gap-2 w-full justify-between">
                                        {otp.map((digit, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    className={`outline-none flex items-center justify-center border text-Dark-Blue-80  text-[39px] w-[68px] h-[68px] border-1 bg-transparent  p-2 text-center rounded-md placeholder:text-Grey-50`}
                                                >
                                                    {otp[otpArr.length - index - 1] !== '' ? "•" : ''}
                                                </span>
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                            <div>
                                <p className='text-sm text-Dark-Blue-60 max-lg:text-center '>Forgot Your Pin
                                    <b
                                        onClick={() => { window.open('https://hdfcsky.com/sky/login') }}
                                        className='text-Primary-Blue-100 text-sm ml-2 cursor-pointer'>
                                        Click Here
                                    </b>
                                </p>
                            </div>
                        </div>
                        <SetSecurityCode />
                        {
                            isTabletOrMobile && <div className='w-full mt-8'>
                                <div className='grid grid-cols-3 gap-x-5 gap-y-3'>
                                    {newArr.map((item, index) =>
                                        <div
                                            onClick={() => { handleButtonClick(index + 1) }}
                                            className='text-lg flex items-center justify-center font-bold rounded-md bg-gray-200 p-3'>
                                            {index + 1}
                                        </div>)}
                                    <div></div>
                                    <div
                                        onClick={() => { handleButtonClick(0) }}
                                        className='text-lg flex items-center justify-center font-bold rounded-md bg-gray-200 p-3'>0</div>
                                    <div className='text-lg flex items-center justify-end font-bold rounded-md w-full px-3'>
                                        <span
                                            onClick={handleBackspace}
                                            class="material-symbols-outlined text-[60px]">
                                            backspace
                                        </span>
                                    </div>

                                </div>
                            </div>
                        }
                    </Card>
                </Fade>
            </div>
        </>
    )
}

export default SecurityCode