import React from 'react'
import Card from './Card'

const Modal = ({children , className ,onClick}) => {
  return (
    <section onClick={onClick} className='absolute z-[300] bg-[rgba(0,0,0,0.6)]  w-full h-screen flex items-center justify-center'>
        <div onClick={e=>e.stopPropagation()} className={`bg-white  p-5 rounded-md ${className}`}>
            {children}
        </div>
    </section>
  )
}

export default Modal